import React, { useEffect } from "react";
import { Input, ItemList, Categories, ScrollBtn } from "../../Components";
import { useDispatch } from "react-redux";
import { getCategories, getItems } from "../../Redux/appSlice";
import {
   Grid,
   makeStyles,
   Typography,
   AppBar,
   Toolbar,
   IconButton,
   Badge,
   Button,
   Fade,
} from "@material-ui/core";

const ShippingPolicy = () => {
   const dispatch = useDispatch();

   useEffect(() => {
      dispatch(getCategories());
      dispatch(getItems());
   }, [dispatch]);
   return (
      <div style={{margin : 30}}>

         <Typography style={{textAlign : "center"}} variant="h4">SHIPPING POLICY</Typography>
<Typography variant="p">Last updated: Oct 01, 2024</Typography> <br></br>
<Typography variant="p"><strong class="website_name company_name">Vanilla n Saffron</strong>, referred to as "we" and "us", operates the website (<a class="website_url" href="https://vanillansaffron.in">https://vanillansaffron.in/</a>) referred to as the "Website". When you place an order through this Website, you accept the following terms. These terms are established to ensure mutual understanding and agreement between both parties regarding our services and to protect the interests of both parties.</Typography><br></br>
<Typography variant="p" class="demoTitle">&nbsp;</Typography><br></br>
<Typography variant="h4">1. General</Typography> <br></br>
<Typography variant="p">Subject to stock availability, we make an effort to keep accurate stock numbers on our website. However, on occasion, there may be an error in our stock count, leading to a situation where we delay to fulfil all your items at the time of purchase. If this occurs, we will deliver the available products to you and get in touch with you to determine if you prefer to wait for the restocked item.</Typography><br></br>
<Typography variant="p" class="demoTitle">&nbsp;</Typography><br></br>
<Typography variant="h4">2. Shipping Costs</Typography> <br></br>
<Typography variant="p">The shipping fee is calculated during the checkout process, based on the weight, dimensions, and delivery location of the items in the order. The shipping payment will be added to the overall purchase amount and will serve as the final cost for the customer.</Typography><br></br>
<Typography variant="p">&nbsp;</Typography><br></br>
<Typography variant="h4">3. Domestic Shipping Policy (Minimum and Maximum timeline)</Typography> <br></br>
<Typography variant="p">For all of our orders we have requested customer's preferred date & time slots, if the orders are from within pune then its ideally shipped before few hours of selected date/time & if orders are outside pune location we ideally take 5-7 business days to delivery based on customer's location.</Typography><br></br>
<Typography variant="p">&nbsp;</Typography><br></br>
<Typography variant="h4">4. Returns</Typography> <br></br>
<Typography variant="h4">4.1 Return Due To Change Of Mind</Typography> <br></br>
<Typography variant="p">At&nbsp;<span class="website_name company_name">Vanilla n Saffron</span>, We currently <b>don't have</b> any return policy. Orders placed cannot be returned.</Typography><br></br>
<Typography variant="p" class="demoTitle">&nbsp;</Typography><br></br>
<Typography variant="h4">5. Delivery Terms</Typography> <br></br>
<Typography variant="h4">5.1 Transit Time Domestically</Typography> <br></br>
<Typography variant="p">Typically, Orders are sent at the scheduled delivery date & time after payment has been received.</Typography><br></br>
<br></br>
<Typography variant="h4">5.2 Transit time Internationally</Typography> <br></br>
<Typography variant="p">International shipping typically takes between 4 to 22 days in transit, with the exact duration depending on the courier chosen. An accurate estimate can be provided during the checkout process when selecting the courier.</Typography><br></br>
<br></br>
<Typography variant="h4">5.3 Dispatch time</Typography> <br></br>
<div class="dispatch-time">
<Typography variant="p" class="same-day-dispatch-no">Orders are typically sent at the scheduled delivery date & time after payment has been received.</Typography><br></br>
</div>
<Typography variant="p">The warehouse operates from Monday to Sunday during standard business hours. During these holidays, measures are taken to minimize shipment delays.</Typography><br></br>
<br></br>
<Typography variant="h4">5.4 Change Of Delivery Address</Typography> <br></br>
<Typography variant="p">Regarding requests for changing the delivery address, we can alter it prior to the dispatch of the order.Delivery charges will be applicable accordingly.</Typography><br></br>
<br></br>
<Typography variant="h4">5.5 Delivery Time Exceeded</Typography> <br></br>
<Typography variant="p">Please contact us if the delivery time has surpassed the expected delivery window, so we can initiate an inquiry. WhatsApp is reachable on all working days to escalate any concerns.</Typography><br></br>
<Typography variant="p" class="demoTitle">&nbsp;</Typography><br></br>
<Typography variant="h4">6. Tracking Notifications</Typography> <br></br>
<Typography variant="p">Once their order is dispatched, customers will receive a meesage notification & delivery tracking link which will allow them to monitor the status of their shipment, as updated by the shipping provider.</Typography><br></br>
<Typography variant="p" class="demoTitle">&nbsp;</Typography><br></br>
<Typography variant="h4">7. Parcels Damaged In Transit</Typography> <br></br>
<Typography variant="p">We offer best possible delivery service for orders which are required to handle with care. Please note that while receiving order we would request video of items delivered to you incase you raise any concerns on items damaged & we will investigate further to best address your concerns.</Typography><br></br>
<Typography variant="p" class="demoTitle">&nbsp;</Typography><br></br>
<Typography variant="h4">8. Duties &amp; Taxes</Typography> <br></br>
<Typography variant="h4">8.1 Sales Tax</Typography> <br></br>
<Typography variant="p">Sales tax has already been applied to the price of the goods as displayed on the website.</Typography><br></br>
<br></br>
<Typography variant="h4">8.2 Import Duties &amp; Taxes</Typography> <br></br>
<div class="duties">
<Typography variant="p" class="price-included">The customs duties and taxes for international deliveries will be taken care of ahead of time, without requiring the customer to pay any additional charges when the shipment reaches its destination country.</Typography><br></br>
</div>
<Typography variant="p" class="demoTitle">&nbsp;</Typography><br></br>
<Typography variant="h4">9. Cancellations</Typography> <br></br>
<Typography variant="p">We currently <b>don't have</b> any cancellation policy. Orders placed cannot be cancel.</Typography><br></br>
<Typography variant="p" class="demoTitle">&nbsp;</Typography><br></br>

<Typography variant="h4">10. Customer service</Typography> <br></br>
<Typography variant="p" class="email">For all customer service enquiries, please email us at&nbsp;<a class="website_email" href="mailto:contact@vanillansaffron.in">contact@vanillansaffron.in</a>.</Typography><br></br>

      </div>
   );
};

export default ShippingPolicy;
