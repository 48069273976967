import React, { useEffect, useRef } from "react";
import { makeStyles, Typography, Grid, IconButton , Chip, Button} from "@material-ui/core";
import { Add, Delete, Remove } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import {
  buyAgainRequest,
  resetBuyAgainRequest
} from "../../Redux/cartSlice";
import { openSnackBar } from "../../Redux/appSlice";
import { BASE_URL } from "../../axios";
import CartItem from "../CartItem/CartItem";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { createTheme } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {
  MDBCard,
  MDBCardBody,
  MDBCardFooter,
  MDBCardHeader,
  MDBCardImage,
  MDBCol,
  MDBContainer,
  MDBProgress,
  MDBProgressBar,
  MDBRow,
  MDBTypography,
} from "mdb-react-ui-kit";

const theme = createTheme({
  palette: {
    order: {
      new: '#87CEEB',
      accepted: '#F8DE7E',
      preparing: '#FF7F50',
      ready: '#5D3FD3',
      intransist: '#6082B6',
      delivered: '#228B22',
      cancel: '#FF5733',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(3),
    maxHeight: 500,
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.spacing(2),
    boxShadow: theme.shadows[2],
    overflow: "hidden",
    transition: "0.3s all ease-in-out"
  },
  imgContainer: {
    height: "auto",
    width: "100%"
  },
  img: {
    height: "100%",
    width: "100%"
  },
  btnContainer: {
    height: "100%",
    [theme.breakpoints.up("md")]: {
      maxWidth: "30%",
      height: "100%"
    }
  },
  info: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  infoTitle: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(2)
    }
  },
  infoPrice: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(4)
    }
  },
  dangerText: {
    color: theme.palette.error.main
  },
  maxHeight: {
    height: "100%",
    display: "flex",
    alignItems: "center"
  },
  paleText: {
    color: theme.palette.grey[600]
  },
  cursor: {
    cursor: "pointer",
    transition: "0.3s all ease-in-out",
    "&:hover": {
      transform: "Scale(0.9)"
    }
  },
  hideMobile: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "flex"
    }
  },
  bold: {
    fontWeight: "bold"
  },
  newOrder : {
    color : theme.palette.order.new,
    fontWeight: "bold"
  },
  acceptedOrder : {
    color : theme.palette.order.accepted,
    fontWeight: "bold"
  },
  preparingOrder : {
    color : theme.palette.order.preparing,
    fontWeight: "bold"
  },
  readyOrder : {
    color : theme.palette.order.ready,
    fontWeight: "bold"
  },
  inTransistOrder : {
    color : theme.palette.order.intransist,
    fontWeight: "bold"
  },
  deliveredOrder : {
    color : theme.palette.order.delivered,
    fontWeight:  "bold"
  },
  cancelOrder : {
    color : theme.palette.order.cancel,
    fontWeight: "bold"
  } 
}));

const OrderItem = ({ item }) => { 
  const classes = useStyles();
  const navigate = useNavigate();
   const dispatch = useDispatch();
   
   const {  buyAgainStatus , buyAgainMessage} = useSelector(
    (state) => state.cart
 );

   useEffect(() => {
       if(buyAgainStatus == true || buyAgainStatus == "true"){
        dispatch(resetBuyAgainRequest());
        navigate("/cart")
       }
   }, [buyAgainStatus]); 
   
   useEffect(() => {
     
      if( (buyAgainStatus == false  || buyAgainStatus == "false"  ) && buyAgainMessage != ""){
       
      if( buyAgainStatus == false  || buyAgainStatus == "false" ){
        dispatch(
          openSnackBar({
             text: "Oops, Sorry please empty cart to buy this order again.",
             severity: "error",
          })
       );
      }

      dispatch(resetBuyAgainRequest());

     }
   }, [buyAgainMessage]);

  const mapThroughItems = (items) => {
    if (items && items.length > 0) {
      return items.map((item, idx) => {
        return <CartItem key={idx} item={item} displayOnly={true} />;
      });
    }
  };


  const SuccessButton = withStyles((theme) => ({
    root: {
       borderColor: theme.palette.success.main,
       backgroundColor: theme.palette.success.main,
       color: theme.palette.common.white,
       letterSpacing: 2,
       transition: "0.3s all ease-in-out",
       "&:hover": {
          transform: "ScaleX(1.05)",
          color: theme.palette.common.white,
          borderColor: theme.palette.success.light,
          backgroundColor: theme.palette.success.light,
       },
    },
 }))(Button);


  let orderDate = item.orderDate ? item.orderDate.split(" ") : [];
  let orderTime =orderDate && orderDate.length == 2 ?  orderDate[1].substring(0,orderDate[1].length-3) : "";
  orderDate = orderDate && orderDate.length == 2 ? orderDate[0].replaceAll("-","/") : "";
  orderDate = orderDate && orderDate.length > 0 ? orderDate.split("/") : "";
  orderDate = orderDate && orderDate.length > 0 ? orderDate[2] + "/" +  orderDate[1] + "/" + orderDate[0] : ""
  return (

    <>
    <section
    key={item.id}
      className="h-100 gradient-custom"
      style={{ backgroundColor: "#FFF", width : "100%"}}
    >
      <MDBContainer className="py-5 h-100">
        <MDBRow    className="justify-content-center align-items-center h-100">
          <MDBCol   lg="10" xl="10">
            <MDBCard  style={ window.screen.width < 480  ? {width : "120%", marginLeft : -28} : {}}>
               
              <MDBCardBody className="p-4" style={ window.screen.width < 480  ? {  width : "100%"} : {}}>

              
             { window.screen.width < 480 &&  <p
                    className="lead fw-normal mb-0"
                    style={{ color: "#a8729a", justifyContent: "center", display : "flex" }}
                  >
                    Receipt
                  </p> }
                  { window.screen.width < 480  && <br></br> }
                <div className="d-flex justify-content-between align-items-center mb-4">

                   
                <p className="small text-muted mb-0" style={{marginTop : window.screen.width < 480 ?  0 : 16}}>
                    Order Status : {item.status}
                  </p>

                  { window.screen.width > 480 && <p
                    className="lead fw-normal mb-0"
                    style={{ color: "#a8729a" }}
                  >
                    Receipt
                  </p>}

                  <div>
                 
                  <SuccessButton
                     variant="text"
                     style={{ width :  window.screen.width < 480 ?  100 : "", fontSize : window.screen.width < 480 ?  12 : ""  }}
                     onClick={() => {
                      dispatch(buyAgainRequest(item.orderId));
                      
                     }}
                  >
                     Buy Again
                  </SuccessButton>
                  
                  </div>
                  
                </div>

                {

                item.productDetails && item.productDetails.length > 0 ? 
                  item.productDetails.map(singleProduct => {

                    const {
                       
                      product_id: {  productMetadataId, qtyPrices },
                   } = singleProduct;

                    const selectedPrice = qtyPrices.filter(
                      (prc) => prc.id == productMetadataId
                   )[0];

                  return <MDBCard className="shadow-0 border mb-4">
                    <MDBCardBody>
                      <MDBRow>
                        <MDBCol md="2">
                          <MDBCardImage
                            src= {BASE_URL + "/images/" + singleProduct.product_id.image1}
                            fluid
                            width={50}
                            style={{borderRadius : "80%", width : "90px",  height : "90px"}}
                            alt="Phone"
                          />
                        </MDBCol>
                        <MDBCol
                          md="6"
                          className="text-center d-flex justify-content-center align-items-center"
                        >
                          <p className="text-muted mb-0">{singleProduct.product_id.title}</p>
                        </MDBCol>
                        
                        
                        <MDBCol
                          md="2"
                          className="text-center d-flex justify-content-center align-items-center"
                        >
                          <p className="text-muted mb-0 small">{(selectedPrice.price +" x "+ singleProduct.quantity)}</p>
                        </MDBCol>
                        <MDBCol
                          md="2"
                          className="text-center d-flex justify-content-center align-items-center"
                        >
                          <p className="text-muted mb-0 small">INR {Math.round(selectedPrice.price * singleProduct.quantity * 100) / 100}</p>
                        </MDBCol>
                      </MDBRow>
                       
                    </MDBCardBody>
                  </MDBCard>})
                  : undefined
                   
                }


               

               

                <div className="d-flex justify-content-between pt-2">
                  <p className="fw-bold mb-0">Order Details</p>
                  {/* <p className="text-muted mb-0">
                    <span className="fw-bold me-4">Total</span> INR {item.price}
                  </p> */}
                </div>

                <div className="d-flex justify-content-between pt-2">
                  <p className="text-muted mb-0">Order Id: {item.orderId}</p>
                  {/* <p className="text-muted mb-0">
                    <span className="fw-bold me-4">Discount</span> $19.00
                  </p> */}
                </div>

                <div className= { window.screen.width > 480  && "d-flex justify-content-between"}>
                  <p className="text-muted mb-0">
                    Scheduled Delivery: { item.deliveryTime }
                  </p>
                  {/* <p className="text-muted mb-0">
                    <span className="fw-bold me-4">GST 18%</span> 123
                  </p> */}
                  <p className="text-muted mb-0">
                    <span className={ window.screen.width > 480  && "fw-bold me-4"}>Total:</span> INR {item.price}
                  </p>
                </div>

                <div className={ window.screen.width > 480  && "d-flex justify-content-between mb-5"}>
                  <p className="text-muted mb-0">
                    Contact No : {item.phone}
                  </p>
                  {item.deliveryPrice && parseInt(item.deliveryPrice) > 0 && <p className="text-muted mb-0">
                    <span className={ window.screen.width > 480  && "fw-bold me-4"}>Delivery Charges</span>{" "}
                    INR {item.deliveryPrice}
                  </p>}
                </div>

                <div className={ window.screen.width > 480  && "d-flex justify-content-between mb-5"}>
                  <p className="text-muted mb-0">
                   {`${item.address == "pickup" ? "Delivery: Self Pickup" : "Delivery Address: " + item.address}`}
                  </p>
                </div>
              </MDBCardBody>
              <MDBCardFooter
                className="border-0 px-4 py-3"
                style={{
                  backgroundColor: "#000",
                  borderBottomLeftRadius: "10px",
                  borderBottomRightRadius: "10px",
                }}
              >

                
                <MDBTypography
                  tag="h5"
                  className="d-flex align-items-center justify-content-end text-white text-uppercase mb-0"
                >
                  Total paid: <span className="h5 mb-0 ms-2">INR {(parseFloat(item.price) + parseFloat(item.deliveryPrice))}</span>
                </MDBTypography>
              </MDBCardFooter>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>

      

    </section>

     
  </>
  
  );
};

export default OrderItem;