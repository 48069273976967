import React, { useEffect } from "react";
import { SingleProduct } from "../../Components";
import { useParams } from "react-router-dom";
import { getSingleItem } from "../../Redux/appSlice";
import { useDispatch } from "react-redux";
import { CircularProgress, makeStyles, Grid, Fab } from "@material-ui/core";
import { useSelector } from "react-redux";
import { WhatsApp } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
   container: {
      display: "flex",
      justifyContent: "center",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5),
      padding: theme.spacing(10),
   },
   fab: {
      position: 'fixed',
      bottom: theme.spacing(8),
      zIndex: 99999999,
      right: theme.spacing(8),
      color : "#FFF"
    },
}));

const SingleProductPage = () => {
   const { id } = useParams();
   const { pending } = useSelector((state) => state.app);
   const classes = useStyles();

   const dispatch = useDispatch();
   useEffect(() => {
      dispatch(getSingleItem(id));
   }, [id, dispatch]);
   return (
      <>
         {!pending ? (
            <SingleProduct />
         ) : (
            <Grid className={classes.container} container>
               <CircularProgress size="5rem" />
            </Grid>
         )}

      <Fab className={classes.fab} style={{backgroundColor : "#25D366", color : "#FFF"}}>
            <a href="https://api.whatsapp.com/send/?phone=%2B918007219428&text=Hi%20There!%20I%20came%20across%20your%20products%20from%20VanillanSaffron%20&%20I%20have%20a%20query.&type=phone_number&app_absent=0" id="instaLink" target="_blank"  >
               <WhatsApp style={{color : "#FFF"}}/>
               </a>
            </Fab>
      </>
   );
};

export default SingleProductPage;
