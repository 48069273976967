import React from "react";
import { AccountInfo } from "../../Components";
import { CircularProgress, makeStyles, Grid, Fab } from "@material-ui/core";
import { WhatsApp } from "@material-ui/icons";


const useStyles = makeStyles((theme) => ({
   fab: {
      position: 'fixed',
      bottom: theme.spacing(8),
      right: theme.spacing(8),
      zIndex : 99999999,
      color : "#FFF"
    },
}));


const Account = () => {

   const classes = useStyles();

   return (
      <>
         <AccountInfo />

         <Fab className={classes.fab} style={{backgroundColor : "#25D366", color : "#FFF"}}>
            <a href="https://api.whatsapp.com/send/?phone=%2B918007219428&text=Hi%20There!%20I%20came%20across%20your%20products%20from%20VanillanSaffron%20&%20I%20have%20a%20query.&type=phone_number&app_absent=0" id="instaLink" target="_blank"  >
               <WhatsApp style={{color : "#FFF"}}/>
               </a>
            </Fab>

      </>
   );
};

export default Account;
