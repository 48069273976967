import React from "react";
import { ContactUsInfo } from "../../Components";
const ContactUs = () => {
   return (
      <>
         <ContactUsInfo />
      </>
   );
};

export default ContactUs;
