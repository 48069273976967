import React, { useRef, useState, useEffect, useCallback } from "react";
import { CheckoutInfo } from "../../Components";
import { OrderSummaryWithDelivery } from "../../Components";
import { Grid, makeStyles, Typography, Button ,Card, CardContent, Fab} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getTotal, fetchDeliveryEstimation , logUserLocationRequest} from "../../Redux/cartSlice";

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { openSnackBar } from "../../Redux/appSlice";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { placeOrder } from "../../Redux/cartSlice";
import { withStyles } from '@material-ui/core/styles';
import { CUSTOMER_CARE_NO } from "../../constants";
import { useLocation } from "react-router-dom";
import moment from 'moment';
import { ScheduleMeeting } from "react-schedule-meeting";
import axios from "axios";

import { WhatsApp } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";

const mapStyle = {
  height: '600px',
  width: '100%'
};

const MAX_DELIVERY_DISTANCE = 30;

const loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

const Map = ({
  inputRef,
  setAddress,
  setSavedAddress,
  setLat,
  setLong,
  setUserDistance,
  setUserDeliveryTime
}) => {
  const DEFAULT_ZOOM = 18;
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyDVUR-3j3lFpGbI0kri_i9MRcDxZ46kUm0"
  });

  const [map, setMap] = React.useState(null);

  const [markerPosition, setMarkerPosition] = useState({
    lat: 18.5248703,
    lng: 73.6978097
  });

  const [defaultLocation, setDefaultLocation] = useState({
    lat: 18.5248703,
    lng: 73.6978097
  });

  const onLoad = useCallback((map) => {
    const bounds = new window.google.maps.LatLngBounds({
      lat: 18.5248703,
      lng: 73.6978097
    });
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const handelClickOnMap = () => {};

  const autoComplete = new window.google.maps.places.Autocomplete(
    inputRef.current
  );

  autoComplete.addListener('place_changed', () => {
    const place = autoComplete.getPlace();
    console.log(place.formatted_address);
    console.log(place.name);
    setAddress(place.name + ", " + place.formatted_address);
    if (!place.geometry || !place.geometry.location) {
      // User entered the name of a Place that was not suggested and
      // pressed the Enter key, or the Place Details request failed.
      alert("this location not available");
    }
    if (place.geometry.viewport || place.geometry.location) {
      // do something
      console.log(place.geometry.location);
      var latitude = place.geometry.location.lat();
      var longitude = place.geometry.location.lng();
      setMarkerPosition({
        lat: latitude,
        lng: longitude
      });

      setLat(latitude);
      setLong(longitude);

      var from = new window.google.maps.LatLng(
        18.570920286334566,
        73.76983318184095
      );
      var fromName = 'Balewadi';
      var dest = new window.google.maps.LatLng(latitude, longitude);
      var destName = 'UserLocation';

      var service = new window.google.maps.DistanceMatrixService();
      service.getDistanceMatrix(
        {
          origins: [from, fromName],
          destinations: [destName, dest],
          travelMode: 'DRIVING'
        },
        callback
      );

      function callback(response, status) {
        if (status == 'OK') {
          var origins = response.originAddresses;
          var destinations = response.destinationAddresses;

          let dists = [];
          let durs = [];

          for (var i = 0; i < origins.length; i++) {
            var results = response.rows[i].elements;
            console.log(results);
            for (var j = 0; j < results.length; j++) {
              var element = results[j];
              var distance = element.distance;
              var duration = element.duration;
              var from = origins[i];
              var to = destinations[j];

              if (distance && duration && distance.text && duration.text) {
                dists.push(distance.text);
                durs.push(
                  parseInt(
                    duration.text.substring(0, duration.text.indexOf(" mins"))
                  )
                );
              }

              console.log("distance", distance);
              console.log("duration", duration);
            }
          }

          if(distance == undefined || duration == undefined){
            setUserDeliveryTime(9999999999);
            setUserDistance("9999 km");
          }

          let longest = 0;
          let longestIndex = 0;
          durs.map((item, index) => {
            if (longest < item) {
              longest = item;
              longestIndex = index;
            }

            setUserDeliveryTime(longest);
            setUserDistance(dists[longestIndex]);
          });
        }
      }

      const bounds = new window.google.maps.LatLngBounds({
        lat: latitude,
        lng: longitude
      });
      map.fitBounds(bounds);
      setMap(map);
    }
  });

  return (
    <div>
      {isLoaded ? (
        <GoogleMap
          onLoad={onLoad}
          center={defaultLocation}
          zoom={DEFAULT_ZOOM}
          mapContainerStyle={mapStyle}
          onClick={handelClickOnMap}
          onUnmount={onUnmount}
        >
          <Marker position={markerPosition} />
        </GoogleMap>
      ) : (
        <></>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    height: "auto",
    flexDirection: "column",
    justifyContent: "flex-start",
    padding: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3)
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(5)
    },
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(8)
    },
    root: {
      maxWidth: 500,
      [theme.breakpoints.up("sm")]: {
         maxHeight: 400,
      },
   },
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(8),
    right: theme.spacing(8),
    color : "#FFF",
zIndex: 99999999,
  },
}));
const Delivery = () => {
  const inputRef = useRef();
  const [value, setValue] = React.useState('homedelivery');
  const [address, setAddress] = React.useState('');
  const [selectedDateTime, setSelectedDateTime] = React.useState('');
  const [savedAddress, setSavedAddress] = React.useState('');
  const [lat, setLat] = React.useState(0);
  const [long, setLong] = React.useState(0);
  const [disabledSave, setDisabledSave] = React.useState(true);
  const [userDistance, setUserDistance] = React.useState("");
  const [distance, setDistance] = React.useState(0);
  const [userDeliveryCost, setUserDeliveryCost] = React.useState(0);
  const [userDeliveryTime, setUserDeliveryTime] = React.useState(0);
  const [deliveryType, setDeliveryType] = React.useState("0");
  const [totalAmount, setTotalAmount] = React.useState(0);
  let tommorow = new Date();
  let dayAfterTommorow = new Date();
  const { state } = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    pending,
    checkoutOrderId,
    premiumDeliveryEstimation,
    standardDeliveryEstimation,
    cartItems
  } = useSelector((state) => state.cart);
  const user = JSON.parse(localStorage.getItem("user"));
  const userId =  user.data._id;

  tommorow.setMonth(new Date().getMonth(), new Date().getDate() + 1);
  dayAfterTommorow.setMonth(new Date().getMonth(), new Date().getDate() + 2);

  const [deliveryDialog, setDeliveryDialog] = React.useState(false);

  const handleDeliveryDialogOpen = () => {
    setDeliveryDialog(true);
  };

  const handleDeliveryDialogClose = (reason) => {
    setDeliveryDialog(false);
    // if(reason.type == "click"){

    //    window.location.href = '/account'; //relative to domain
    // }
  };

  useEffect(() => {
    loadScript("https://checkout.razorpay.com/v1/checkout.js");
    dispatch(fetchDeliveryEstimation());
    cartItems.map((ci) => {
      if (ci.product_id.exclusive == "1") {
        setDeliveryType("1");
      }
    });
  }, []);

  const calculateDelivery = (data, userDistance) => {
    let isBroken = false;
        data.map((delivery) => {
          if (isBroken) {
            return;
          }
          if (userDistance < delivery.maxKm && userDistance >= delivery.minKm ) {
            setUserDeliveryCost(delivery.price);
            isBroken = true;
            return;
          }
        });
  }

  useEffect(() => {
    console.log("userDeliveryTimeuserDeliveryTimeuserDeliveryTime")
    console.log(userDeliveryTime)
    console.log(address)
    console.log(savedAddress)
    if (userDistance != "" && userDistance != undefined) {
      let dist = userDistance;
      console.log("userDistance" + userDistance);
      dist = dist.replace(",", "");
      dist = dist.substring(0, dist.indexOf(" km"));
      dist = parseFloat(dist);
      dispatch(logUserLocationRequest(  {distance: dist, time: JSON.stringify(userDeliveryTime), address: JSON.stringify(address) } ))
      setDistance(dist);
      console.log("dist" + dist);

      if (dist > MAX_DELIVERY_DISTANCE) {
        dispatch(
          openSnackBar({
             severity: "error",
             text: "Destination entered is outside city limits. Your drop location is too far way. Contact us on WhatsApp for pick-up.",
          })
       );
        setUserDeliveryCost(0);
        setUserDistance("");
        setUserDeliveryTime(0);
        setSavedAddress("");
      }

      if (deliveryType == "0") {
        calculateDelivery(standardDeliveryEstimation,Math.round(dist))
      } else {
        calculateDelivery(premiumDeliveryEstimation,Math.round(dist))
      }
    }
  }, [userDistance]);

  const handleChange = (event) => {
    setValue(event.target.value);
    if(event.target.value == "pickup"){
      setSavedAddress("pickup");
      setDistance(0);
      setSelectedDateTime("")
    }else{
      setSavedAddress("");
    }
  };

  useEffect(() => {
    if (!pending) {
      dispatch(getTotal());
    }
  }, [pending, dispatch]);

  useEffect(() => {
    if(checkoutOrderId && checkoutOrderId != undefined && checkoutOrderId != "")
    {
      navigate("/order-confirmed/"+checkoutOrderId);
    }
    // handleSuccessDialogOpen()
  }, [checkoutOrderId]);

  const inputStyle = {
    boxShadow: 'inset 0 0 10px #eee !important',
    border: '1px solid #eee',
    width: '70%',
    height: '30px',
    marginLeft: '4px',
    borderRadius: '10px',
    fontWeight: '300 !important',
    outline: 'none',
    padding: '10px 20px',
    marginBottom: '10px',
    marginTop: "10px"
  };

  const getFormattedDate = (date) => {
    var year = date.getFullYear();

    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    return day + '/' + month + '/' + year;
  };

  const formatAMPM = (date) => {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  };

  const GreenRadio = withStyles({
    root: {
      color: "#000",
      '&$checked': {
        color: "#000"
      }
    },
    checked: {}
  })((props) => <Radio color="default" {...props} />);

  const availableTimeslots = [1, 2, 3, 4, 5].map((id) => {

    const currentTime = new Date().getHours();
    const selectedStartTime = currentTime <= 13 ? new Date(
      new Date(new Date().setDate(new Date().getDate() + id)).setHours(
        9,
        0,
        0,
        0
      )
    ) : new Date(
      new Date(new Date().setDate(new Date().getDate() + id)).setHours(
        18,
        0,
        0,
        0
      )
    );

    const allTimeSlots = new Date(
      new Date(new Date().setDate(new Date().getDate() + id)).setHours(
        9,
        0,
        0,
        0
      )
    ) 

    if(id == 1){
      return {
        id,
        startTime: selectedStartTime,
        endTime: new Date(
          new Date(new Date().setDate(new Date().getDate() + id)).setHours(
            21,
            0,
            0,
            0
          )
        )
      };
    }else{
      return {
        id,
        startTime: allTimeSlots,
        endTime: new Date(
          new Date(new Date().setDate(new Date().getDate() + id)).setHours(
            21,
            0,
            0,
            0
          )
        )
      };
    }
  
  });

  return (
    <Grid container className={classes.container}>
       <Card className={classes.root} style={{width : "100%"}}>
      <CardContent> 
      <FormControl component="fieldset">

      
        <FormLabel style={{color : "grey", marginBottom : 28}} component="legend">Select Delivery Details</FormLabel>
        <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
               <FormControlLabel value="pickup" control={<GreenRadio />} label="Pick-up" />

               {value == "pickup" ? <Typography component="h5" variant="body2">
                  {`Pickup is available from Baner hub only & pick-up details will be shared when order are placed OR reach out to customer care incase of any query - ${CUSTOMER_CARE_NO}`}
               </Typography> : undefined}
               <br></br>
               {value == "pickup" ? <Typography component="h5" variant="body2">
                  {`Customer can also arrange pickup via third party delivery service.`}
               </Typography> : undefined}

               <FormControlLabel style={{ marginTop: 30 }} value="homedelivery" control={<GreenRadio />} label="Home Delivery" />
            </RadioGroup>
      </FormControl>
      
      {value == "homedelivery" && savedAddress == "" ? (
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            marginTop: 20,
            marginLeft: 30
          }}
        >
          <Typography component="h7" variant="body2">
            Search drop location from below search box & save to select drop
            location
          </Typography>
          <Typography component="h7" variant="body2">
            Currently we are delivering in pune city only
          </Typography>
          {/* <label style={{ marginLeft: 4, marginTop: 16 }}>Search drop location from below search box & save to select drop location</label> */}
          <br></br>
          <input
            placeholder="Search location here"
            ref={inputRef}
            style={inputStyle}
          />
          <Map
            setUserDistance={setUserDistance}
            setUserDeliveryTime={setUserDeliveryTime}
            inputRef={inputRef}
            setAddress={setAddress}
            setSavedAddress={setSavedAddress}
            setLat={setLat}
            setLong={setLong}
          />

          <Button
            type="submit"
            fullWidth
            onClick={() => {
              setSavedAddress(address);
            }}
            variant="contained"
            disabled={distance > 0 && distance <= MAX_DELIVERY_DISTANCE ? false : true}
            color="primary"
            style={{ marginTop: 30, width: 300, alignSelf: "center" }}
          >
            <Typography variant="body1">Save</Typography>
          </Button>
        </div>
      ) : undefined}

      {distance <= MAX_DELIVERY_DISTANCE && value == "homedelivery" && savedAddress != "" ? (
        <Typography style={{ margin: 30 }} component="h5" variant="body2">
          Drop Address :- {savedAddress}{" "}
          <span
            style={{ color: "blue", textDecoration: "underline" }}
            onClick={() => {
              setAddress("");
              setSavedAddress("");
            }}
          >
            Change
          </span>
          <br></br>
          Delivery distance :- {userDistance}
          <br></br>
          Delivery Cost :- {userDeliveryCost}

          <br></br>
          Delivery Type :- {deliveryType == "0" ? "Standard" : "Premium (Few of your product in cart requires premium delivery)"}

        </Typography>
      ) : undefined}
       

       </CardContent>
       </Card>

      {/* https://codesandbox.io/s/demo-3o6lrx?file=/src/App.js:58-81 */}
      {/* https://lrojas94.github.io/react-timeslot-calendar/ */}

      <br></br>
      <br></br>
      <br></br>
       
      {distance <= MAX_DELIVERY_DISTANCE && savedAddress != ""  && <Card className={classes.root} style={{width : "100%"}}>
      <CardContent> 

      {distance <= MAX_DELIVERY_DISTANCE  && savedAddress != "" && (
        <FormLabel  style={{color : "grey"}} >Select Preferred Timeslot</FormLabel>
      )}


     
       
       {selectedDateTime == "" && <div style={{ margin: MAX_DELIVERY_DISTANCE }}>
          <ScheduleMeeting
            borderRadius={10}
            primaryColor="#3f5b85"
            eventDurationInMinutes={120}
            availableTimeslots={availableTimeslots}
            onStartTimeSelect={(e) =>
              setSelectedDateTime(
                getFormattedDate(e.startTime) + ", " + formatAMPM(e.startTime)
              )
            }
          />
        </div>}
      

      {distance <= MAX_DELIVERY_DISTANCE  && savedAddress != "" && selectedDateTime != "" && (
        <Typography style={{ margin: 30 }} component="h5" variant="body2">
          Time Slot :- {selectedDateTime}{" "}
          <span
            style={{ color: "blue", textDecoration: "underline" }}
            onClick={() => {
              setSelectedDateTime("");
            }}
          >
            Change
          </span>
        </Typography>
      )}

</CardContent>
</Card>}

      {(value != "" &&  savedAddress != "" && selectedDateTime != "") ? (
        distance <= MAX_DELIVERY_DISTANCE ? (
          <div style={{ justifyContent: "center", display: "flex" }}>
            <OrderSummaryWithDelivery
              userDeliveryCost={userDeliveryCost}
              setTotalAmount={setTotalAmount}
            />
          </div>
        ) : undefined
      ) : undefined}

      {(value != "" && value == "homedelivery" && savedAddress != "" && selectedDateTime != "") ||
      (value != "" && value == "pickup" && selectedDateTime != "") ? (
        distance <= MAX_DELIVERY_DISTANCE ? (
          <Button
            type="submit"
            fullWidth
            onClick={() => {
              axios
                .post("/CreateOrder.php", {
                  price: (totalAmount + parseFloat(userDeliveryCost)) * 100,
                  customername: state.name,
                  email: state.email,
                  contactno: state.phoneNumber,
                  placeOrder : {
                    userId : userId,
                    price: totalAmount,
                    deliveryPrice: userDeliveryCost,
                    latitude: lat,
                    longitude: long,
                    address: savedAddress,
                    orderDate: new Date().toLocaleDateString(),
                    deliveryDate: tommorow.toLocaleDateString(),
                    deliveryTime: selectedDateTime,
                    deliveryType: value,
                    isDeliveryPremium: deliveryType,
                    status: "New",
                    email: state.email,
                    name: state.name,
                    phone: state.phoneNumber,
                    razorpayPaymentId: "null",
                    razorpayOrderId: "null",
                    razorpaySignature: "null"
                  }
                })
                .then((response) => {
                  if (response && response.data && response.data.orderId) {
                    const options = {
                      key: "rzp_live_Z8WhSRVjRAWJHI",
                      // key: "rzp_test_XRB1lPBn8eFvhX",
                      currency: "INR",
                      amount:
                        (totalAmount + parseFloat(userDeliveryCost)) * 100,
                      name: "Vanilla N Saffron",
                      description: "Gourmet Baked Goodies",
                      image:
                        "https://linen-goat-992498.hostingersite.com/api/images/vanilla_n_saffron.png",
                      order_id: response.data.orderId,
                      handler: function (handlerResponse) {

                        if(handlerResponse && handlerResponse.razorpay_payment_id
                          && handlerResponse.razorpay_order_id && handlerResponse.razorpay_signature
                         ){
                          dispatch(
                            placeOrder({
                              price: totalAmount,
                              deliveryPrice: userDeliveryCost,
                              latitude: lat,
                              longitude: long,
                              address: savedAddress,
                              orderDate: new Date().toLocaleDateString(),
                              deliveryDate: tommorow.toLocaleDateString(),
                              deliveryTime: selectedDateTime,
                              deliveryType: value,
                              isDeliveryPremium: deliveryType,
                              status: "New",
                              email: state.email,
                              name: state.name,
                              phone: state.phoneNumber,
                              razorpayPaymentId: handlerResponse.razorpay_payment_id,
                              razorpayOrderId: handlerResponse.razorpay_order_id,
                              razorpaySignature: handlerResponse.razorpay_signature
                            })
                          );
                        }else{
                          navigate("/failure");
                        }
                      },
                      prefill: {
                        name: state.name,
                        email: state.email,
                        contact: state.phoneNumber
                      }
                    };

                    const paymentObject = new window.Razorpay(options);
                    paymentObject.open();
                  }
                })
                .catch((error) => {
                  console.error(error);
                  navigate("/failure");
                });
            }}
            variant="contained"
            color="primary"
            style={{ marginTop: 30, width: 300, alignSelf: "center" }}
          >
            <Typography variant="body1">Place Order</Typography>
          </Button>
        ) : undefined
      ) : undefined}

       

<Fab className={classes.fab} style={{backgroundColor : "#25D366", color : "#FFF"}}>
            <a href="https://api.whatsapp.com/send/?phone=%2B918007219428&text=Hi%20There!%20I%20came%20across%20your%20products%20from%20VanillanSaffron%20&%20I%20have%20a%20query.&type=phone_number&app_absent=0" id="instaLink" target="_blank"  >
               <WhatsApp style={{color : "#FFF"}}/>
               </a>
            </Fab>
            
    </Grid>
  );
};

export default Delivery;
