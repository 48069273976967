import React, { useEffect } from "react";
import MuiAlert from "@material-ui/lab/Alert";
import icSomethingWentWrong from "../../assets/somethingwrong.png";
import { blue } from "@material-ui/core/colors";

const AppFailure = () => {
   


   return (
      <>
          <MuiAlert severity="error">Oops, Something went wrong. Please contact our customer support - 8007219428</MuiAlert>
          <img src={icSomethingWentWrong} alt="SomethingWentWrong" style={{
            display : "block",
            marginLeft : "auto",
            marginRight : "auto",
            width : "50%"
          }}>
          </img>
      </>
   );
};

export default AppFailure;
