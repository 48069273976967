import { createTheme } from "@material-ui/core";

const theme = createTheme({
   body: {
      fontFamily: ["auntie"].join(","),
    },
   typography: {
      
      fontFamily: [
         "Barlow Condensed",
         "Roboto",
         "Helvetica",
         "Arial",
         "sans-serif",
         "auntie",
         "Cadman"
      ].join(","),
      h1: {
         fontFamily: ["auntie"].join(","),
         fontSize: "150px",
      },
      h2: {
         fontFamily: ["auntie"].join(","),
         fontSize: "100px",
      },
      h3: {
         fontFamily: ["auntie"].join(","),
         fontSize: "56px",
      },
      h4: {
         fontFamily: ["Bellefair", "serif"].join(","),
         fontSize: "32px",
      },
      h5: {
         fontSize: "28px",
         letterSpacing: 4.75,
         fontFamily: ["auntie"].join(","),
      },
      h6: {
         fontSize: "28px",
         fontFamily: ["auntie"].join(","),
      },
      body1: {
         letterSpacing: 2.5,
         fontFamily: ["Cadman"].join(","),
         lineHeight: 1.65,
      },
      body2: {
         letterSpacing: 1.5,
         fontSize: "16px",
         fontFamily: ["Cadman"].join(","),
      },
      subtitle1: {
         fontSize: "14px",
         letterSpacing: 2.35,
      },
      subtitle2: {
         fontSize: "16px",
         letterSpacing: 2.7,
      },
      button : {
         fontFamily: ["Amaranth"].join(","),
      },
      span : {
         fontFamily: ["Amaranth"].join(","),
      },
      p : {
         fontFamily: ["Amaranth"].join(","),
       }
   },
   palette: {
      primary: {
         main: "#0b0d17",
      },
      secondary: {
         main: "#ffffff",
      } ,
      order: {
         new: '#87CEEB',
         accepted: '#F8DE7E',
         preparing: '#FF7F50',
         ready: '#5D3FD3',
         intransist: '#6082B6',
         delivered: '#228B22',
         cancel: '#FF5733',
       },
   },
   breakpoints: {
      values: {
         xs: 0,
         sm: 576,
         md: 768,
         lg: 1124,
         xl: 1440,
      },
   },
});

export default theme;
