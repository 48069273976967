import React, { useEffect, useRef } from "react";
import OrderItem from "../CartItem/OrderItem";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
   Grid,
   makeStyles,
   CircularProgress,
   Typography,
   Button,
   withStyles,
} from "@material-ui/core";
import classNames from "classnames";
import { clearCart } from "../../Redux/cartSlice";
import { openSnackBar } from "../../Redux/appSlice";
import { getTotal } from "../../Redux/cartSlice";

const useStyles = makeStyles((theme) => ({
   container: {
      display: "flex",
      justifyContent: "center",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(10),
   },
   progress: {
      padding: theme.spacing(10),
   },
   priceDiv: {
      backgroundColor: theme.palette.grey[100],
      borderRadius: theme.spacing(2),
      boxShadow: theme.shadows[2],
      overflow: "hidden",
      padding: theme.spacing(3),
   },
   btnContainer: {
      marginTop: theme.spacing(2),
   },
   navigateBtn: {
      marginBottom: theme.spacing(4),
   },
   marginRightTwo: {
      marginRight: theme.spacing(2),
   },
   marginTopTwo: {
      marginTop: theme.spacing(2),
   },
   letterSpace: {
      letterSpacing: 3,
   },
}));

const mapThroughItems = (items) => {
   if(items && items.length > 0){
   return items.map((item, idx) => {
      return <OrderItem key={idx} item={item} />;
   });
   }
};
const DangerButton = withStyles((theme) => ({
   root: {
      borderColor: theme.palette.error.main,
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
      letterSpacing: 2,
      transition: "0.3s all ease-in-out",
      "&:hover": {
         transform: "ScaleX(1.05)",
         color: theme.palette.common.white,
         borderColor: theme.palette.error.light,
         backgroundColor: theme.palette.error.light,
      },
   },
}))(Button);

const OrderHistoryList = ({userOrders}) => {
   
   const classes = useStyles();
   const navigate = useNavigate();

   console.log("userOrdersuserOrders" , userOrders);
   

   return (
      <>
         
            <Grid className={classes.container} container item xs={10} lg={8}>
               {mapThroughItems(userOrders)}
            </Grid>
      </>
   );
};

export default OrderHistoryList;
