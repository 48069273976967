import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  Typography,
  makeStyles,
  Button,
  alpha,
  Chip,
  Card,
  Box,
  CardContent,
  Fab
} from "@material-ui/core";
import classNames from "classnames";
import { addToCart } from "../../Redux/cartSlice";
import { openSnackBar } from "../../Redux/appSlice";
import { BASE_URL } from "../../axios";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "./styles.css";
import VideoPlayer from 'react-video-player-extended';
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import LocalShippingOutlined from "@material-ui/icons/LocalShippingOutlined";
import icVideoPlay from "../../assets/ic_video_play.png";
import icYoutube from "../../assets/ic_youtube.png";


const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
    justifyContent: "space-around",
    height: "auto",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(10)
    }
  },
  imgContainer: {
    width: "100%",
    height: "auto",
    boxShadow: theme.shadows[3]
  },
  img: {
    width: "100%",
    height: "auto"
  },
  marginTopTwo: {
    marginTop: theme.spacing(2)
  },
  paleText: {
    color: alpha("#333", 0.8)
  },
  letterSpace: {
    letterSpacing: 2.5
  },
  chipPadding: {
    padding: theme.spacing(1),
    margin: 16
  }
}));

const SingleProduct = () => {
  const { singleItem } = useSelector((state) => state.app);
  const { pending, error } = useSelector((state) => state.cart);
  const user = useSelector((state) => state.user.user);
  const [mainIndex, setMainIndex] = useState(0);

  const classes = useStyles();
  const {
    title,
    qtyPrices,
    description,
    category,
    image1,
    image2,
    image3,
    image4,
    image5,
    video,
    shelfLife,
    _id,
    exclusive
  } = singleItem;
  console.log("video.......");
  console.log(video);
  const [selectedPriceCard, setSelectedPriceCard] = useState(
    parseInt(qtyPrices ? qtyPrices[0].id : 0)
  );
  const dispatch = useDispatch();

  const slideNext = () => {
    if (mainIndex < items.length - 1) {
      setMainIndex(mainIndex + 1);
    }
  };

  const slidePrev = () => {
    if (mainIndex > 0) {
      setMainIndex(mainIndex - 1);
    }
  };

  const setImageIndex = (index) => {
    
      setMainIndex(index);
    
  };

  const handleClick = () => {
    if (!user) {
      dispatch(openSnackBar({ severity: "error", text: "Please Log In" }));
    } else {
      dispatch(addToCart({ id: _id, productMetadataId: selectedPriceCard }));
      if (!error && !pending) {
        dispatch(
          openSnackBar({
            severity: "success",
            text: "Item has been added to cart"
          })
        );
      } else if (error && !pending) {
        dispatch(
          openSnackBar({
            severity: "error",
            text: "Something went wrong"
          })
        );
      }
    }
  };

  let items = [];
  if (image1) {
    items.push(
      <div className="item">
        <img src={BASE_URL + "/images/" + image1} className="media" />
      </div>
    );
  }

  if (image2) {
    items.push(
      <div className="item">
        <img src={BASE_URL + "/images/" + image2} className="media" />
      </div>
    );
  }

  if (image3) {
    items.push(
      <div className="item">
        <img src={BASE_URL + "/images/" + image3} className="media" />
      </div>
    );
  }

  if (image4) {
    items.push(
      <div className="item">
        <img src={BASE_URL + "/images/" + image4} className="media" />
      </div>
    );
  }

  if (image5) {
    items.push(
      <div className="item">
        <img src={BASE_URL + "/images/" + image5} className="media" />
      </div>
    );
  }

  if (video) {
    items.push(
      <div className="item">
        <video width="100%" controls className="media">
          <source src={BASE_URL + "/videos/" + video} type="video/mp4" />
        </video>
      </div>
    );
  }

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} sm={4}>
        <div className={classes.imgContainer}>
          {/* {https://codesandbox.io/s/react-carousel-images-videos-5i2ug?file=/src/App.js:531-536} */}
          {/* <img src={BASE_URL + "/images/" + image1} alt={title} className={classes.img} /> */}
          <div className="carousel">
            <AliceCarousel
              activeIndex={mainIndex}
              disableDotsControls
              key={mainIndex}
              mouseTracking
              disableButtonsControls
              items={items}
            />
            {items.length > 1 ? (
              <p className="index">{`${mainIndex + 1}/${items.length}`}</p>
            ) : undefined}
            <div className="caption-container"></div>

            {items.length > 1 ? (
              <div className="btn-prev" onClick={slidePrev}>
                &lang;
              </div>
            ) : undefined}
            {items.length > 1 ? (
              <div className="btn-next" onClick={slideNext}>
                &rang;
              </div>
            ) : undefined}
          </div>

          <div></div>
        </div>
       {items.length > 1 ?  <div className="preview">
        {image1 && image1 != "" ? <div className="item" onClick={()=> setImageIndex(0)}>
          <img src={BASE_URL + "/images/" + image1} className="media-preview" />
        </div> : undefined}
        {image2 && image2 != "" ? <div className="item">
          <img src={BASE_URL + "/images/" + image2} className="media-preview" onClick={()=> setImageIndex(1)}/>
        </div> : undefined}
        {image3 && image3 != "" ?<div className="item">
          <img src={BASE_URL + "/images/" + image3} className="media-preview" onClick={()=> setImageIndex(2)} />
        </div> : undefined}
        {image4 && image4 != "" ?<div className="item">
          <img src={BASE_URL + "/images/" + image4} className="media-preview" onClick={()=> setImageIndex(3)}/>
        </div> : undefined}
        {image5 && image5 != "" ? <div className="item">
          <img src={BASE_URL + "/images/" + image5} className="media-preview" onClick={()=> setImageIndex(4)} />
        </div> : undefined}
        {video && video != "" ?<div className="item" onClick={()=> setImageIndex(5)}>
          <img src={BASE_URL + "/images/" + image1} className="item-video" onClick={()=> setImageIndex(5)} />
          <img src={icYoutube} className="item-video2" onClick={()=> setImageIndex(5)} />
        </div> : undefined}
        </div> : undefined}
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography className={classes.marginTopTwo} variant="h4">
          {title}
        </Typography>

        <Chip
          label={category}
          variant="outlined"
          icon={<LocalOfferIcon />}
          className={classes.chipPadding}
        />

        <Chip
          label={"ShelfLife: " + shelfLife}
          variant="outlined"
          icon={<AccessTimeIcon />}
          className={classes.chipPadding}
        />

        <Chip
          label={
            exclusive == 0
              ? "Standard delivery recommended"
              : "Premium delivery recommended"
          }
          variant="outlined"
          icon={<LocalShippingOutlined />}
          className={classes.chipPadding}
        />

        <Typography
          className={classNames(classes.paleText, classes.marginTopTwo)}
          style={{ whiteSpace: "pre-line" }}
          variant="body1"
        >
          {description}
        </Typography>
        {/* <Typography className={classes.marginTopTwo} variant="subtitle2">
               INR {price}
            </Typography> */}

        <div style={{ marginTop: 16, marginBottom: 80, display: "flex" }}>
          {qtyPrices &&
            qtyPrices.map((priceCard) => (
              <div
                onClick={() => setSelectedPriceCard(parseInt(priceCard.id))}
                style={{ width: 120, height: 40, marginLeft: 16 }}
              >
                <Box
                  border={2}
                  borderColor={
                    selectedPriceCard == parseInt(priceCard.id)
                      ? "#50B8E2"
                      : "#D3D3D3"
                  }
                  borderRadius={10}
                >
                  <CardContent>
                    <Typography component="h6" variant="h6" style={{fontSize : 20}}>
                      {priceCard.qty}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      component="h4"
                    >
                      INR {priceCard.price}
                    </Typography>
                  </CardContent>
                </Box>
              </div>
            ))}
        </div>

        <Button
          className={classNames(classes.letterSpace, classes.marginTopTwo)}
          fullWidth
          variant="contained"
          color="primary"
          disabled={pending}
          onClick={handleClick}
        >
          Add to Cart
        </Button>
      </Grid>

     

    </Grid>
  );
};

export default SingleProduct;
