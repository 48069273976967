import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  Typography,
  Button,
  makeStyles,
  withStyles
} from "@material-ui/core";
import { logOut } from "../../Redux/userSlice";
import { resetCart } from "../../Redux/cartSlice";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useParams , useLocation} from "react-router-dom";
import QRCode from "react-qr-code";
import { fetchOrderDetails } from "../../Redux/cartSlice";

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(10),
    marginTop: theme.spacing(4),
    padding: theme.spacing(4)
  },
  profileCard: {
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.spacing(2),
    boxShadow: theme.shadows[2],
    padding: theme.spacing(3)
  },
  alertContainer: {
    backgroundColor: theme.palette.error.light,
    padding: theme.spacing(2),
    color: theme.palette.common.white
  },
  marginTopOne: {
    marginTop: theme.spacing(1)
  },
  marginTopThree: {
    marginTop: theme.spacing(3)
  },
  marginLeftTwo: {
    marginLeft: theme.spacing(4)
  }
}));

const WarningButton = withStyles((theme) => ({
  root: {
    borderColor: theme.palette.error.main,
    backgroundColor: "transparent",
    color: theme.palette.primary,
    letterSpacing: 2,
    paddingRight: theme.spacing(6),
    transition: "0.3s all ease-in-out",
    paddingLeft: theme.spacing(6),
    "&:hover": {
      transform: "Scale(1.05)",
      color: theme.palette.common.white,
      borderColor: theme.palette.error.main,
      backgroundColor: theme.palette.error.main
    }
  }
}))(Button);

const SuccessButton = withStyles((theme) => ({
  root: {
    borderColor: theme.palette.success.main,
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    letterSpacing: 2,
    transition: "0.3s all ease-in-out",
    "&:hover": {
      transform: "ScaleX(1.05)",
      color: theme.palette.common.white,
      borderColor: theme.palette.success.light,
      backgroundColor: theme.palette.success.light
    }
  }
}))(Button);

const PaymentsInfo = () => {
  const classes = useStyles();
  const {id} = useParams();
  const [amount, setAmount] = React.useState(0);
  const [QR, setQR] = React.useState('');
  const dispatch = useDispatch();
  const { orderDetails } = useSelector((state) => state.cart);
  const generateQR = (amount, id) => {
    return `upi://pay?pa=vanillansaffron@okhdfcbank&pn=Vanilla%20n%20Saffron&am=${amount}.00&cu=INR&aid=uGICAgMCO7NvDKw&tid=${id}&tr=${id}&tn=${id}`;
  }

  useEffect(() => {
    dispatch(fetchOrderDetails(id));
 }, []);

 useEffect(() => {
  if(orderDetails && orderDetails.length > 0){
    setAmount(parseInt(orderDetails[0].price)  + parseInt(orderDetails[0].deliveryPrice));
  }
}, [orderDetails]);

  
 
  return (
    <Grid
      container
      className={classes.container}
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        container
        item
        xs={12}
        sm={8}
        md={6}
        className={classes.profileCard}
      >
        <Grid item xs={10} className={classes.textContainer}>
          <Typography
            fontWeight={"fontWeightBold"}
            variant="body1"
            color="initial"
          >
            <strong>{`Request for Payment`}</strong>
          </Typography>
        </Grid>
        
        
        <Grid item xs={12} className={classes.marginTopThree}>
          <Grid
            container
            className={classes.marginTopThree}
            justifyContent="center"
            alignItems="center"
          >
            <Grid>
              <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={QR}
                viewBox={`0 0 256 256`}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} className={classes.marginTopThree}>
          <Grid
            container
            className={classes.marginTopThree}
            justifyContent="center"
            alignItems="center"
          >
            <Grid>
            <Typography
            fontWeight={"fontWeightBold"}
            variant="body1"
            color="initial"
          >
            { `Vanilla n Saffron - Payment of order ${id} for amount INR ${amount}`}
          </Typography>
            </Grid>
          </Grid>
        </Grid>


      </Grid>
    </Grid>
  );
};

export default PaymentsInfo;
