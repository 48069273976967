import React from "react";
import { PaymentsInfo } from "../../Components";
const Payments = () => {
   return (
      <>
         <PaymentsInfo />
      </>
   );
};

export default Payments;
