import React from "react";
import { Hero } from "../../Components";
const Home = () => {
   return (
      <>
         <Hero />
      </>
   );
};

export default Home;
