import React, { useEffect } from "react";
import { CheckoutInfo, OrderSummary } from "../../Components";
import { Grid, makeStyles, Fab } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getTotal } from "../../Redux/cartSlice";
import { WhatsApp } from "@material-ui/icons";


const useStyles = makeStyles((theme) => ({
   container: {
      height: "auto",
      justifyContent: "space-around",
      padding: theme.spacing(1),
      [theme.breakpoints.up("sm")]: {
         padding: theme.spacing(3),
      },
      [theme.breakpoints.up("md")]: {
         padding: theme.spacing(5),
      },
      [theme.breakpoints.up("lg")]: {
         padding: theme.spacing(8),
      },
   },
   fab: {
      position: 'fixed',
      bottom: theme.spacing(8),
      right: theme.spacing(8),
      color : "#FFF",
zIndex: 99999999,
    },
}));

const Checkout = () => {
   const classes = useStyles();
   const dispatch = useDispatch();
   const { pending } = useSelector((state) => state.cart);
   useEffect(() => {
      if (!pending) {
         dispatch(getTotal());
      }
   }, [pending, dispatch]);
   return (
      <Grid container className={classes.container}>
         <OrderSummary />
         <CheckoutInfo />

         <Fab className={classes.fab} style={{backgroundColor : "#25D366", color : "#FFF"}}>
            <a href="https://api.whatsapp.com/send/?phone=%2B918007219428&text=Hi%20There!%20I%20came%20across%20your%20products%20from%20VanillanSaffron%20&%20I%20have%20a%20query.&type=phone_number&app_absent=0" id="instaLink" target="_blank"  >
               <WhatsApp style={{color : "#FFF"}}/>
               </a>
            </Fab>
            
      </Grid>
   );
};

export default Checkout;
