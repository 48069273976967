import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const loginUser = createAsyncThunk(
   "user/login",
   async (user, { rejectWithValue }) => {
      try {
         const { data } = await axios.post("/auth/Login.php", user);

         localStorage.setItem(
            "user",
            JSON.stringify({ data: data.user, token: data.token })
         );
         return data.user;
      } catch (err) {
         return rejectWithValue(err.response.data);
      }
   }
);
export const registerUser = createAsyncThunk(
   "user/register",
   async (user, { rejectWithValue }) => {
      try {
         const { lastName, firstName, email, username, password } = user;
         console.log("registerUser calling")
         const axiosConfig = {
            headers: {
            }
            };
         axios.defaults.withCredentials = true;
         // headers: {
         //    'content-Type': 'application/json',
         //    "Accept": "/",
         //    "Cache-Control": "no-cache",
         //    "Cookie": "__test=c1f9be4ae0b73ee7ea1a08b9666386fb; expires=2025-04-06T09:20:47.428Z; path=/"
         //    }

         const { data } = await axios.post("/auth/Register.php", {
            lastName,
            firstName,
            email,
            username,
            password,
         }, axiosConfig);
         localStorage.setItem(
            "user",
            JSON.stringify({ data: data.user, token: data.token })
         );
         return data.user;
      } catch (err) {
         return rejectWithValue(err.response.data);
      }
   }
);

const user = JSON.parse(localStorage.getItem("user"));

export const userSlice = createSlice({
   name: "user",
   initialState: {
      user: user ? user.data : null,
      pending: false,
      error: false,
      errorMessage: "",
   },
   reducers: {
      logOut: (state) => {
         state.user = null;
         state.pending = false;
         state.error = false;
         state.errorMessage = "";
         localStorage.removeItem("user");
      },
   },
   extraReducers: {
      [loginUser.pending]: (state) => {
         state.pending = true;
         state.error = false;
         state.errorMessage = "";
      },
      [loginUser.fulfilled]: (state, action) => {
         state.pending = false;
         state.error = false;
         state.user = action.payload;
         state.errorMessage = "";
      },
      [loginUser.rejected]: (state, action) => {
         state.pending = false;
         state.error = true;
         state.errorMessage = action.payload.error;
      },
      [registerUser.pending]: (state) => {
         state.pending = true;
         state.error = false;
         state.errorMessage = "";
      },
      [registerUser.fulfilled]: (state, action) => {
         state.pending = false;
         state.error = false;
         state.user = action.payload;
         state.errorMessage = "";
      },
      [registerUser.rejected]: (state, action) => {
         state.pending = false;
         state.error = true;
         state.errorMessage = action.payload.error;
      },
   },
});

export const { logOut } = userSlice.actions;

export default userSlice.reducer;
