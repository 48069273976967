import React, { useEffect } from "react";
import { Input, ItemList, Categories, ScrollBtn } from "../../Components";
import { useDispatch } from "react-redux";
import { getCategories, getItems } from "../../Redux/appSlice";
import {
   Grid,
   makeStyles,
   Typography,
   AppBar,
   Toolbar,
   IconButton,
   Badge,
   Button,
   Fade,
} from "@material-ui/core";

const RefundsAndCancellations = () => {
   const dispatch = useDispatch();

   useEffect(() => {
      dispatch(getCategories());
      dispatch(getItems());
   }, [dispatch]);
   return (
      <div style={{margin : 30}}>

<Typography style={{textAlign : "center"}} variant="h4">PRICING AND RETURN/CANCELLATION POLICY</Typography>
        <br></br>
        <br></br>
<Typography variant="p">Last updated: Oct 01, 2024</Typography>


<Typography variant="p">Thank you for shopping at <strong>https://vanillansaffron.in</strong></Typography> <br></br>
<Typography variant="p">If, for any reason, You are not completely satisfied with a purchase, we invite You to review our Policy. </Typography> <br></br>
<Typography variant="p">The following terms are applicable for any products that you purchased with us.</Typography> <br></br><br></br>

 

<Typography variant="h4"><strong>INTERPRETATION AND DEFINITIONS</strong></Typography>
<pre> </pre>
<Typography variant="h4"><strong>Interpretation</strong></Typography>
<Typography variant="p">The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</Typography> <br></br>
<Typography variant="h4"><strong>Definitions</strong></Typography>
<Typography variant="p">For the purposes of this Pricing & Return/Cancellation Policy:</Typography> <br></br>

<Typography variant="p"><strong>Goods</strong> refer to the items offered for sale on the Service.</Typography> <br></br>
<Typography variant="p"><strong>Orders</strong> mean a request by You to purchase Goods from Us.</Typography> <br></br>
<Typography variant="p"><strong>Service</strong> refers to the Website.</Typography> <br></br>
<Typography variant="p"><strong>Website</strong> refers to My Site, accessible from <strong>https://vanillansaffron.in</strong></Typography> <br></br>
<Typography variant="p"><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</Typography> <br></br>

 

<br></br><Typography variant="h4"><strong>GENERAL RETURN POLICY</strong></Typography>

We currently <b>don't have</b> any return policy. Orders placed cannot be returned.

<br></br><br></br>
 

<br></br><Typography variant="h4"><strong>REFUND POLICY</strong></Typography>

We currently <b>don't have</b> any refund policy. Orders placed will be not refundable.

 

 <br></br><br></br>

<Typography variant="h4"><strong>CONTACT US</strong></Typography>
<Typography variant="p">If you have further clarification and questions, Please do not hesitate to contact us through our:</Typography> <br></br>

<Typography variant="p">Email Address: <span style={{color: "#000080"}}><strong>contact@vanillansaffron.in</strong></span></Typography> <br></br>

<Typography variant="p">You will be updated for their query status through their<strong> EMAIL,</strong> provided that contact information is recorded to us.</Typography> <br></br>

      </div>
   );
};

export default RefundsAndCancellations;
