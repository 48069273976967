import React from "react";
import {
  Grid,
  makeStyles,
  alpha,
  Typography,
  Button,
  withStyles,
  Link,
  Fab
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import img from "../../assets/background.jpg";
import logo from "../../assets/vanilla_n_saffron.png";
import icInsta from "../../assets/ic_insta.png";
import icYoutube from "../../assets/ic_youtube.png";
import { WhatsApp } from "@material-ui/icons";


const fabStyle = {
  position: 'absolute',
  bottom: 10,
  right: 60,
};

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(8),
    right: theme.spacing(8),
    zIndex : 99999999,
    color : "#FFF"
  },
  container: {
    minHeight: "calc(100vh - 56px)",
    height: "auto",
    [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
      minHeight: "calc(100vh - 48px)"
    },
    [theme.breakpoints.up("sm")]: {
      minHeight: "calc(100vh - 64px)"
    },
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    color: "#000",
    justifyContent: "center",
    // background: `${alpha(theme.palette.grey[900], 0.999)} url(${img})`,
    // backgroundBlendMode: "overlay"
    // alignItems: "center",
  },
  whiteText: {
    color: "#000"
  },
  scriptText: {
    fontFamily: "Meow Script, cursive",
    marginBottom: theme.spacing(2),
    maxWidth: 375,
    fontSize: "2rem",
    [theme.breakpoints.up("sm")]: {
      maxWidth: 450,

      fontSize: "3rem"
    }
  },
  bigText: {
    maxWidth: 600,
    marginBottom: theme.spacing(3),
    fontSize: "3.5rem",
    [theme.breakpoints.up("sm")]: {
      maxWidth: 720,
      marginBottom: theme.spacing(6),
      fontSize: "4.5rem"
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "6rem"
    }
  },
  padding: {
    paddingBottom: theme.spacing(4)
  }
}));

const WhiteButton = withStyles({
  root: {
    borderColor: "#fff",
    color: "#fff",
    textTransform: "capitalize",
    paddingLeft: 56,
    paddingRight: 56,
    letterSpacing: 2,
    "&:hover": {
      backgroundColor: "#fff",
      color: "#0b0d17"
    }
  }
})(Button);

const ContactUsInfo = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <Grid container className={classes.container}>
      <div
        style={{
          alignItems: "flex-start",
          display: "flex",
          flex: 1,
          margin: 70,
          height: 10
        }}
      >
        <img style={{ width: window.screen.width < 480  ? 60 : 150, height:  window.screen.width < 480   ? 60 : 150, marginLeft : window.screen.width < 480  ? 50 : 0 }} src={logo} alt={"title"} />

        <div style={{ marginLeft:window.screen.width < 480  ?20 : 60, marginTop: window.screen.width < 480  ? -20 : 30 }}>
          <Typography variant="h1" style={{fontSize : window.screen.width < 480  ? 22 : 30}} color="blue-gray">
            Vanilla n Saffron
          </Typography>
          <Typography variant="body1" color="gray" style={{fontSize : window.screen.width < 480  ? 13 : undefined}}>
            vanillansaffron@gmail.com
          </Typography>
          <Typography variant="body1" color="gray">
            (+91) 8007219428
          </Typography>
          <Typography variant="body1" color="gray">
            S no 25, Baner Rd, Pune, Maharashtra 411045
          </Typography>
        </div>
      </div>

      <div style={{ marginLeft: 50, marginRight: 50, marginTop:  window.screen.width < 480   ? 90 : 50 }}>
        <Typography variant="body1" color="blue-gray">
          Vanilla n Saffron, an indian bakery based in Pune since 2023 serving gourmet cakes, pastries, cookies, biscuits
          and much more.
        </Typography>
        <br></br>
        <Typography variant="body1" color="blue-gray">
          All our products are freshly baked with utmost hygiene and with real ingredients . We believe in providing a taste and experience that would stay forever and inspire the senses of our esteemed customers. We are committed to using finest ingredients sourced exclusively to create a masterpiece for your celebration or just a sweet pick-me-up. From classic cakes to crumbly biscuits, every bite is a testament to our passion for quality and flavor.
        </Typography>
        <br></br>
        <Typography variant="body1" color="blue-gray">
          Our desserts are available for a pick up or can get delivered at your door step with prior confirmation of your order. Delivery charges are applicable as per actuals.
        </Typography>

        <Typography variant="body1" color="blue-gray">
          We do customize any size, fillings or shape as per the customer's
          request. We are reachable all days on whatsapp from 8AM to 8PM, just drop your requirement on 8007219428
        </Typography>
      </div>

      <div style={{ flex: 1, display: "flex", alignItems: "flex-start" }}>
      <div style={{ marginLeft: 60 , marginTop :  window.screen.width < 480 ?  20 : undefined   }}>
          <Typography variant="h4" color="blue-gray" style={{color : "#000", fontSize : window.screen.width < 480 ? 20 : undefined}}>
            Follow us
          </Typography>
          <div style={{display : "flex"}}>
          <a href="https://www.instagram.com/vanilla_n_saffron/?igsh=MW85ZmUyM216bWVrYw%3D%3D" id="instaLink" target="_blank"  >
            <img
                style={{ width: 30, height: 30, color : "#FFF", marginTop :  6  }}
                src={icInsta}
                alt={"title"}
              />
          </a>
            
          <a href="https://www.youtube.com/@VanillanSaffron" id="instaLink" target="_blank"  >
          <img
              style={{ width: 45, height: 45, marginLeft: 10 }}
              src={icYoutube}
              alt={"title"}
            />
          </a>  
          </div>
        </div>

        <div
          style={{
            marginLeft: 60,
            flex: 1,
            alignItems: "flex-end",
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "20%"
          }}
        >
          <div>
            <Typography variant="h4" color="blue-gray">
              Policies
            </Typography>
            <Link
              href="/privacypolicy"
              underline="hover"
              style={{ color: "#000" , fontFamily :"Cadman"}}
            >
              {'Privacy Policy'}
            </Link>
            <br></br>
            <Link
              href="/termsAndConditions"
              underline="hover"
              style={{ color: "#000",  fontFamily :"Cadman" }}
            >
              {'Terms and Conditions'}
            </Link>
            <br></br>
            <Link
              href="/refundsAndCancellations"
              underline="hover"
              style={{ color: "#000" ,  fontFamily :"Cadman"}}
            >
              {'Pricing & Refunds/Cancellations'}
            </Link>
            <br></br>
            <Link
              href="/shippingPolicy"
              underline="hover"
              style={{ color: "#000" ,  fontFamily :"Cadman"}}
            >
              {'Shipping Policy'}
            </Link>
            <div></div>
          </div>
        </div>
      </div>

      <Fab className={classes.fab} style={{backgroundColor : "#25D366", color : "#FFF"}}>
      <a href="https://api.whatsapp.com/send/?phone=%2B918007219428&text=Hi%20There!%20I%20came%20across%20your%20products%20from%20VanillanSaffron%20&%20I%20have%20a%20query.&type=phone_number&app_absent=0" id="instaLink" target="_blank"  >
           <WhatsApp style={{color : "#FFF"}}/>
           </a>
       </Fab>

    </Grid>
  );
};

export default ContactUsInfo;
