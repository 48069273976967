import React, { useEffect } from "react";
import { OrderHistoryList } from "../../Components";
import { fetchOrdersHistory } from "../../Redux/cartSlice";
import { useSelector, useDispatch } from "react-redux";
import { WhatsApp } from "@material-ui/icons";
import { CircularProgress, makeStyles, Grid, Fab } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
   fab: {
      position: 'fixed',
      bottom: theme.spacing(8),
      right: theme.spacing(8),
      color : "#FFF",
zIndex: 99999999,
    },
}));




const OrderHistory = () => {
   const dispatch = useDispatch();

 const classes = useStyles();

   useEffect(() => {
      dispatch(fetchOrdersHistory());
   }, [dispatch]);

   const { userOrders } = useSelector(
      (state) => state.cart
   );

   return (
      <>
         <OrderHistoryList userOrders={userOrders} />
         <Fab className={classes.fab} style={{backgroundColor : "#25D366", color : "#FFF"}}>
            <a href="https://api.whatsapp.com/send/?phone=%2B918007219428&text=Hi%20There!%20I%20came%20across%20your%20products%20from%20VanillanSaffron%20&%20I%20have%20a%20query.&type=phone_number&app_absent=0" id="instaLink" target="_blank"  >
               <WhatsApp style={{color : "#FFF"}}/>
               </a>
            </Fab>
            
      </>
   );
};

export default OrderHistory;
