import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const addToCart = createAsyncThunk("cart/add", async formData => {
  const { id, productMetadataId } = formData;
  console.log("formData")
  console.log(formData)
  const user = JSON.parse(localStorage.getItem("user"));
  const { data } = await axios.post(`/AddToCart.php`, {
    productId: id,
    userId: user.data._id,
    productMetadataId: productMetadataId
  });

  return data;
});

export const fetchCartItems = createAsyncThunk("cart/fetchAll", async () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const { data } = await axios.post(`/UserCart.php`, { userId: user.data._id });

  return data;
});

export const fetchOrderDetails = createAsyncThunk("cart/fetchOrderDetails", async _id => {
  const { data } = await axios.post(`/FetchOrderDetails.php`, { id: _id });
  return data;
});

export const fetchOrdersHistory = createAsyncThunk("orderhistory/fetchAll", async () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const { data } = await axios.post(`/UserOrderHistory.php`, { userId: user.data._id });

  return data;
});

export const fetchConfirmedOrder = createAsyncThunk("orderconfirm/fetchAll", async orderId => {
  const { data } = await axios.post(`/FetchOrder.php`, { orderId: orderId });
  return data;
});

export const clearCart = createAsyncThunk("cart/clearCart", async () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const { data } = await axios.delete(`/ClearCart.php?userId=${user.data._id}`);
  return data;
});

export const removeItemFromCart = createAsyncThunk(
  "cart/removeItem",
  async _id => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { data } = await axios.delete(`/CartItemDelete.php?productId=${_id}&userId=${user.data._id}`);
    return data;
  }
);

export const incrementCartItem = createAsyncThunk(
  "cart/incrementItem",
  async _id => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { data } = await axios.patch(`/CartIncrement.php?productId=${_id}&userId=${user.data._id}`, {
      product_id: _id
    });
    return data;
  }
);
export const decrementCartItem = createAsyncThunk(
  "cart/removeItem",
  async _id => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { data } = await axios.patch(`/CartDecrement.php?productId=${_id}&userId=${user.data._id}`, {
      product_id: _id
    });
    return data;
  }
);

export const fetchDeliveryEstimation = createAsyncThunk(
  "cart/deliveryestimation",
  async () => {
    const { data } = await axios.patch(`/FetchDeliveryEstimation.php`);
    return data;
  }
);

export const logUserLocationRequest = createAsyncThunk(
  "cart/userLocationRequest",
  async formData => {
    const {distance, time, address } = formData;
    const user = JSON.parse(localStorage.getItem("user"));
    const { data } = await axios.patch(`/LogUserLocationRequest.php`, { distance: distance,time: time, address: address , userId : user.data._id });
    return data;
  }
);

export const buyAgainRequest = createAsyncThunk(
  "cart/buyagain",
  async orderId => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { data } = await axios.patch(`/BuyAgainRequest.php`, { orderId: orderId, userId : user.data._id });
    return data;
  }
);

export const checkout = createAsyncThunk("cart/checkout", async formData => {
  const { email, name, phoneNumber, amount } = formData;
  const { data } = await axios.post("/cart/checkout", {
    email,
    name,
    phoneNumber,
    amount
  });
  return data;
});

export const verifyPayment = createAsyncThunk(
  "cart/verify",
  async reference => {
    await axios.get(`/cart/paystack/checkout?reference=${reference}`);

    return;
  }
);
export const placeOrder = createAsyncThunk(
  "cart/placeOrder",
  async ({
    orderId,
    price,
    deliveryPrice,
    latitude,
    longitude,
    address,
    orderDate,
    deliveryDate,
    deliveryTime,
    deliveryType,
    isDeliveryPremium,
    status,
    email, name, phone, razorpayPaymentId , razorpayOrderId, razorpaySignature
  }) => {
   const user = JSON.parse(localStorage.getItem("user"));
   const userId =  user.data._id;
   const { data } = await axios.post(`/PlaceOrder.php`, {
      userId,
      orderId,
      price,
    deliveryPrice,
    latitude,
    longitude,
    address,
    orderDate,
    deliveryDate,
    deliveryTime,
    deliveryType,
    isDeliveryPremium,
    status,
    email, name, phone, razorpayPaymentId , razorpayOrderId, razorpaySignature
    });
    return data;
  }
);

export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cartItems: [],
    cartLength: 0,
    error: false,
    pending: false,
    total: 0,
    url: "",
    orderDetails: [],
    premiumDeliveryEstimation : [],
    standardDeliveryEstimation : [],
    userOrders : [],
    buyAgainStatus : false,
    buyAgainMessage : ""
  },
  reducers: {
    resetCart: state => {
      state.cartItems = [];
      state.cartLength = 0;
      state.error = false;
      state.pending = false;
      state.total = 0;
    },
    resetBuyAgainRequest: state => {
      state.buyAgainStatus = false;
      state.buyAgainMessage = "";
    },
    getTotal: state => {
      state.total = state.cartItems.reduce((a, b) => {

        const selectedPrice = b.product_id.qtyPrices.filter(
          (prc) => prc.id == b.product_id.productMetadataId
       )[0];

       console.log("state.cartItems")
       console.log(state.cartItems)

        return (
          Math.round(
            (a + b.quantity * Math.round(selectedPrice.price * 100) / 100) * 100
          ) / 100
        );
      }, 0);
    }
  },
  extraReducers: {
    [addToCart.pending]: (state, action) => {
      console.log("addToCart.pending");
      state.pending = true;
      state.error = false;
    },
    [addToCart.fulfilled]: (state, action) => {
      console.log("addToCart.fulfilled");
      state.pending = false;
      state.error = false;
      let { products } = action.payload;
      console.log(action);
      if (products) {
        products = products.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });
        state.cartItems = products;
        state.cartLength = action.payload.count;
      }
    },
    [addToCart.rejected]: (state, action) => {
      console.log("addToCart.rejected");
      state.pending = false;
      state.error = true;
    },
    [fetchCartItems.pending]: (state, action) => {
      console.log("fetchCartItems.pending");
      state.pending = true;
      state.error = false;
    },

    [fetchCartItems.fulfilled]: (state, action) => {
      state.pending = false;
      state.error = false;
      let { products } = action.payload;
      if (products) {
        products = products.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });
        state.cartItems = products;
        state.cartLength = action.payload.count;
      }
    },

    [fetchCartItems.rejected]: (state, action) => {
      state.pending = false;
      state.error = true;
    },


    [fetchOrdersHistory.pending]: (state, action) => {
      console.log("fetchOrdersHistory.pending");
      state.pending = true;
      state.error = false;
    },

    [fetchOrdersHistory.fulfilled]: (state, action) => {
      console.log("userOrders action.payload",action.payload.order)
      state.pending = false;
      state.error = false;
      state.userOrders = action.payload.order;
    },

    [fetchOrdersHistory.rejected]: (state, action) => {
      state.pending = false;
      state.error = true;
    },
    
    [fetchConfirmedOrder.pending]: (state, action) => {
      console.log("fetchConfirmedOrder.pending");
      state.pending = true;
      state.error = false;
    },

    [fetchConfirmedOrder.fulfilled]: (state, action) => {
      console.log("fetchConfirmedOrder action.payload",action.payload.order)
      state.pending = false;
      state.error = false;
      state.confirmedOrder = action.payload.order;
    },

    [fetchConfirmedOrder.rejected]: (state, action) => {
      state.pending = false;
      state.error = true;
    },

    [fetchOrderDetails.pending]: (state, action) => {
      console.log("fetchOrderDetails.pending");
      state.pending = true;
      state.error = false;
    },

    [fetchOrderDetails.fulfilled]: (state, action) => {
      console.log("orderDetails action.payload",action.payload.order)
      state.pending = false;
      state.error = false;
      state.orderDetails = action.payload.order;
    },

    [fetchOrderDetails.rejected]: (state, action) => {
      state.pending = false;
      state.error = true;
    },


    [clearCart.pending]: (state, action) => {
      console.log("clearCart.pending");
      state.pending = true;
      state.error = false;
    },

    [clearCart.fulfilled]: (state, action) => {
      state.pending = false;
      state.error = false;
      state.cartItems = [];
      state.cartLength = 0;
    },

    [clearCart.rejected]: (state, action) => {
      state.pending = false;
      state.error = true;
    },
    [removeItemFromCart.pending]: (state, action) => {
      console.log("removeItemFromCart.pending");
      state.pending = true;
      state.error = false;
    },

    [removeItemFromCart.fulfilled]: (state, action) => {
      state.pending = false;
      state.error = false;
      let { products } = action.payload;
      products = products.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });
      state.cartItems = products;
      state.cartLength = action.payload.count;
    },

    [removeItemFromCart.rejected]: (state, action) => {
      state.pending = false;
      state.error = true;
    },
    [incrementCartItem.pending]: (state, action) => {
      state.error = false;
    },

    [incrementCartItem.fulfilled]: (state, action) => {
      state.error = false;
      let { products } = action.payload;
      products = products.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });
      state.cartItems = products;
      state.cartLength = action.payload.count;
    },

    [incrementCartItem.rejected]: (state, action) => {
      state.error = true;
    },
    [decrementCartItem.pending]: (state, action) => {
      state.error = false;
    },

    [decrementCartItem.fulfilled]: (state, action) => {
      state.error = false;
      let { products } = action.payload;
      products = products.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });
      state.cartItems = products;
      state.cartLength = action.payload.count;
    },
    [decrementCartItem.rejected]: (state, action) => {
      state.error = true;
    },
    
    
    [fetchDeliveryEstimation.pending]: (state, action) => {
      state.error = false;
    },

    [fetchDeliveryEstimation.fulfilled]: (state, action) => {
      state.error = false;
      let { premium, standard } = action.payload;
      state.premiumDeliveryEstimation = premium;
      state.standardDeliveryEstimation = standard;
    },
    [fetchDeliveryEstimation.rejected]: (state, action) => {
      state.error = true;
    },
    
    [buyAgainRequest.pending]: (state, action) => {
      state.error = false;
      state.buyAgainStatus = false;
      state.buyAgainMessage = "";
    },
    [buyAgainRequest.fulfilled]: (state, action) => {
      state.error = false;
      let { status, message } = action.payload;
      state.buyAgainStatus = status;
      state.buyAgainMessage = message;
    },
    [buyAgainRequest.rejected]: (state, action) => {
      state.error = true;
      state.buyAgainStatus = false;
      state.buyAgainMessage = "";
    },


    [checkout.pending]: state => {
      console.log("checkout.pending");
      state.pending = true;
      state.error = false;
    },
    [checkout.fulfilled]: (state, action) => {
      state.pending = false;
      state.error = false;
      state.url = action.payload.url;
    },
    [checkout.rejected]: state => {
      state.pending = false;
      state.error = true;
    },
    [verifyPayment.pending]: state => {
      console.log("verifyPayment.pending");
      state.pending = true;
      state.error = false;
    },
    [verifyPayment.fulfilled]: (state, action) => {
      state.pending = false;
      state.error = false;
    },
    [verifyPayment.rejected]: state => {
      state.pending = false;
      state.error = true;
    },
    [placeOrder.pending]: state => {
      console.log("placeOrder.pending");
      state.pending = true;
      state.error = false;
    },
    [placeOrder.fulfilled]: (state, action) => {
      console.log("Action",action.payload);
      state.pending = false;
      state.error = false;
      state.checkoutOrderId = action.payload.orderId;
    },
    [placeOrder.rejected]: state => {
      state.pending = false;
      state.error = true;
    }
  }
});

export const { resetCart, getTotal, resetBuyAgainRequest } = cartSlice.actions;

export default cartSlice.reducer;
