import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import {
   makeStyles,
   Typography,
   Button,
   Grid,
   Box,
   TextField,
   Snackbar
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { Link } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { loginUser } from "../../Redux/userSlice";
import { openSnackBar } from "../../Redux/appSlice";

 import axios from "axios";
import { BASE_URL } from "../../axios";

const useStyles = makeStyles((theme) => ({
   container: {
      minHeight: "100vh",
   },
   formContainer: {
      minHeight: 320,
      minWidth: window.screen.width < 480 ? 330  : 320,
      maxWidth: window.screen.width < 480 ? 330  : 444,
      backgroundColor: theme.palette.grey[100],
      padding: theme.spacing(3),
      borderRadius: theme.spacing(2),
      boxShadow: theme.shadows[2],
   },
   iconContainer: {
      backgroundColor: theme.palette.primary.main,
      height: theme.spacing(5),
      width: theme.spacing(5),
      borderRadius: "50%",
      color: "#fff",
   },
   link: {
      color: theme.palette.primary.light,
      transition: "all 0.1s ease-in-out",
      "&:hover": {
         color: theme.palette.primary.dark,
      },
      marginLeft: theme.spacing(1),
      textDecoration: "none",
   },
   flex: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
   },
   centerMargin: {
      marginRight: "auto",
      marginLeft: "auto",
   },
   marginTopTwo: {
      marginTop: theme.spacing(2),
   },
   marginTopThree: {
      marginTop: theme.spacing(3),
   },
   text: {
      color: theme.palette.grey[600],
      letterSpacing: 2.5,
   },
   letterSpace: {
      letterSpacing: 2,
   },
}));
const ResetPassword = () => {
   const classes = useStyles();
   const { user, pending, error, errorMessage } = useSelector(
      (state) => state.user
   );
   const dispatch = useDispatch();

   const [notificationOpen, setNotificationOpen] = useState(false);
   const [notificationMesssage, setNotificationMesssage] = useState(false);
   const [email, setEmail] = useState("");

   const isMounted = useRef(false);

   const handleSubmit = (event) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      const email = data.get("email");
      ResetPassword(email)
   };

   const ResetPassword = (email) => {
      axios
      .post(BASE_URL + "/ResetPassword.php", {
         "email" : email
      })
      .then((response) => {
         console.log("response")
         console.log(response);
         if(response && response.data && response.data.status){
            setNotificationMesssage(response.data.message)
            setNotificationOpen(true)
            setEmail("")
         }
      })
      .catch((error) => {
         console.error(error)
         setEmail("")
         setNotificationMesssage("Cannot reset password, Please try again later.")
         setNotificationOpen(true)
      });
}

   useEffect(() => {
      if (isMounted.current) {
         if (error) {
            dispatch(
               openSnackBar({
                  severity: "error",
                  text: errorMessage,
               })
            );
         }
      } else {
         isMounted.current = true;
      }
   }, [errorMessage, error, dispatch]);

   return (
      <>
         {user && <Navigate to="/account" />}
         <Grid
            className={classNames(
               classes.container,
               classes.flex,
               classes.letterSpace
            )}
            container
         >
            <Grid
               className={classes.formContainer}
               item
               direction="column"
               container
               xs={12}
               sm={8}
               md={6}
               lg={4}
            >
               <span
                  className={classNames(
                     classes.iconContainer,
                     classes.flex,
                     classes.centerMargin
                  )}
               >
                  <LockOutlinedIcon />
               </span>

               <Typography component="h1" variant="h4">
                  Reset Password
               </Typography>

               <Box
                  component="form"
                  className={classes.marginTopTwo}
                  onSubmit={handleSubmit}
               >
                  <TextField
                     required
                     fullWidth
                     type="text"
                     className={classes.marginTopTwo}
                     id="email"
                     value={email}
                     onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                     label="Email"
                     name="email"
                     autoFocus
                  />

                  <Button
                     type="submit"
                     fullWidth
                     variant="contained"
                     color="primary"
                     className={classes.marginTopThree}
                     disabled={pending}
                  >
                     <Typography
                        variant="body1"
                        className={classes.letterSpace}
                     >
                        Reset password
                     </Typography>
                  </Button>

                  
               </Box>
            </Grid>
         </Grid>
         <Snackbar
                     anchorOrigin={{ vertical : "top", horizontal : "right" }}
                     open={notificationOpen}
                     onClose={() => {
                        setNotificationOpen(false)
                     }}
                     message={notificationMesssage}
                     key={'top' + 'right'}
                     />
      </>
   );
};

export default ResetPassword;
